/* eslint-disable camelcase */
import React, {useState} from 'react'
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'

import {Link} from 'gatsby'
import EllipsisLoader from '../../EllipsisLoader'
import OrderItem from '../../OrderItem'

export default ({orders, loading}) => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const loadingTrue = loading === 'true'
  // const displayMoreButton =
  //   !loadingTrue && (meta ? meta.page.offset + 1 < meta.page.total : false)

  // Show this when customer has no orders
  if (!loadingTrue && orders.length === 0) {
    return (
      <div className="message info">
        <h3>No orders</h3>
        <p>
          When you place an order, it will appear here.{' '}
          <Link to="/store/">Go shopping!</Link>
        </p>
      </div>
    )
  }

  // Render one order of the entire order data
  const renderOrders = () => {
    const ordersToRender = orders.slice(0, currentIndex)
    return ordersToRender.map(order => (
      <OrderItem givenOrder={order} key={order.id} />
    ))
  }

  // Only render the Load More button when there are more orders to display
  const renderLoadMoreButton = () => {
    if (currentIndex < orders.length) {
      return (
        <Button className="button-primary" onClick={onLoadMoreOrders}>
          Load More Orders
        </Button>
      )
    }
    return null
  }

  // When the Load More button is clicked, increase the currentIndex so the next order of the order data will be displayed
  const onLoadMoreOrders = () => {
    const newCurrentIndex = currentIndex + 1
    setCurrentIndex(newCurrentIndex)
  }

  return (
    <div>
      <h2 className="red">My Orders</h2>
      {/* {orders.map(order => (
        <OrderItem givenOrder={order} key={order.id} />
      ))} */}
      {renderOrders()}
      <Grid>
        <Grid.Column textAlign="center">
          {/* {displayMoreButton && (
            <Button className="button-primary" onClick={onClick}>
              Load More Orders
            </Button>
          )} */}
          {renderLoadMoreButton()}
          {loading === 'failed' && <Label color="red">Request failed</Label>}
          {loadingTrue && <EllipsisLoader height="450px" />}
        </Grid.Column>
      </Grid>
    </div>
  )
}
