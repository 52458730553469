import React, {useState, useEffect, useContext} from 'react'
import Tab from 'semantic-ui-react/dist/commonjs/modules/Tab'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import {navigate} from 'gatsby'
import styled from 'styled-components'
import {get} from 'https'
import SEO from '../components/SEO'
import AuthContext from '../components/Context/AuthContext'
import OrderItemList from '../components/Account/OrderItemList'
import AddressInfo from '../components/AddressInfo'
import {getUser} from '../services/auth'
import {Inner} from '../components/styles'
import Layout from '../components/Layout'
import PageHeader from '../components/Layout/PageHeader'
import {getAllOrders} from '../utils/WordPress'
import {isOnMobile} from '../utils'

const OrdersPageWrapper = styled.div`
  background: ${props => props.theme.grey3};
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px 0px 60px 0px;
  div.tab-1 > div.ui.bottom.attached.segment.active.tab {
      padding-bottom: 0;
  }
  .ui.tabular.menu .item {
    font-size: ${isOnMobile() == true ? '100%' : '20px'};
    min-width: ${isOnMobile() == true ? '50%;' : '180px;'}
    ${isOnMobile() == true ? 'max-width: 50%;' : ''}
    text-transform: uppercase;
  }
  .ui.segment {
    padding: 20px;
  }
`

const ProfileWrapper = styled.div``

const Profile = ({location}) => {
  const [loading, setLoading] = useState('true')
  const [orders, setOrders] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const {loggedInState, performLogout} = useContext(AuthContext)

  useEffect(() => {
    if (loggedInState === false) {
      navigate('/login/')
    }
  }, [loggedInState])

  // Fetch all orders from WC
  useEffect(() => {
    if (loggedInState === true) {
      const {token} = getUser()
      setLoading('true')
      getAllOrders(token, performLogout)
        .then(async allOrders => {
          try {
            const newOrders = []

            // Loop through orders and push to array
            for (let i = 0; i < allOrders.length; i += 1) {
              newOrders.push({...allOrders[i]})
            }

            setOrders(newOrders)
            setLoading('false')
            console.log('newOrders: ', newOrders)
          } catch (err) {
            setOrders([])
            setLoading('false')
          }
        })
        .catch(console.log)
    }
  }, [loggedInState])

  // When user changes tab
  const onTabChange = (event, data) => {
    setActiveIndex(data.activeIndex)
    setLoading(false)
  }

  const panes = [
    /* Render My Orders Tab */
    {
      menuItem: {content: 'Orders', icon: 'unordered list', key: 'orders-tab'},
      render: () => (
        <Tab.Pane>
          <OrderItemList
            orders={orders}
            loading={loading}
            // onClick={onMoreButton}
          />
        </Tab.Pane>
      ),
    },
    /* Render My Address Tab */
    {
      menuItem: {content: 'Account', icon: 'truck', key: 'address-tab'},
      render: () => (
        <Tab.Pane>
          <AddressInfo setContinueAllowed={() => {}} />
        </Tab.Pane>
      ),
    },
  ]

  return (
    <Layout location={location} background={'#cad4e0'}>
      <SEO location={location} title="My Account" />
      <ProfileWrapper>
        <PageHeader location={location} flush>
          My Account
        </PageHeader>
        <OrdersPageWrapper>
          <Inner>
            <Tab
              className={`tab-${activeIndex}`}
              renderActiveOnly
              panes={panes}
              onTabChange={onTabChange}
            />
          </Inner>
        </OrdersPageWrapper>
      </ProfileWrapper>
    </Layout>
  )
}
export default Profile
