import React, {useState} from 'react'
import {Link, navigate} from 'gatsby'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import {analyticsPayNowOnOldOrder} from '../../../services/GoogleAnalytics'

const PayLabels = ({order}) => {
  // Hide all except "order status" labels
  const hideAll = order.status === 'cancelled' || order.status === 'refunded'

  // Check if a pay-on-delivery payment-method was selected
  const payOnDelivery =
    order.payment_method === 'cod' || order.payment_method === 'ccondel'
  const paidIconUnpaidColour = payOnDelivery ? 'grey' : 'red'
  // Show "PAID" icon if 'processing' (and not a pay-on-delivery) or if 'completed'
  const paidIcon =
    (order.status === 'processing' && !payOnDelivery) ||
    order.status === 'completed'

  // Only done shipping when 'completed'
  const shippedStatus = order.status === 'completed'

  // Only show click-to-pay when order is either 'pending' or 'on-hold'
  const showClickToPay =
    order.status === 'pending' ||
    order.status === 'on-hold' ||
    order.status === 'failed'

  const callAnalyticsPayNowOnOldOrder = () =>
    analyticsPayNowOnOldOrder(order.id)

  return (
    <>
      {!hideAll && (
        <Label
          icon={paidIcon ? 'check' : 'hourglass half'}
          color={paidIcon ? 'green' : paidIconUnpaidColour}
          content={paidIcon ? 'PAID' : 'NOT PAID'}
        />
      )}
      {!hideAll && showClickToPay && (
        <Link
          to={`/cart?orderid=${order.id}`}
          onClick={callAnalyticsPayNowOnOldOrder}
        >
          <Label
            pointing="left"
            icon="dollar sign"
            color="blue"
            content="CLICK TO PAY"
          />
        </Link>
      )}
      {!hideAll && (
        <Label
          icon="shipping"
          color={shippedStatus ? 'green' : 'red'}
          content={shippedStatus ? 'FULFILLED' : 'UNFULFILLED'}
        />
      )}
      <Label
        icon="info"
        color={order.status === 'failed' ? 'red' : 'orange'}
        content={order.status.toUpperCase()}
      />
    </>
  )
}

export default PayLabels
