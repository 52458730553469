/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'

import styled from 'styled-components'
import EllipsisLoader from '../EllipsisLoader'
import {isLoggedIn} from '../../services/auth'
import {formatMoney, mapMonths, formatTime} from '../../utils'
import PayLabels from './PayLabels'

const OrderItemWrapper = styled.div`
  margin: ${props => (props.flush ? '0' : '20px -20px -60px')};
  .order-details {
    background: #e7e7e7;
    padding: 30px 40px 100px 40px;
    background: ${props => props.theme.grey2};
    p,
    h3 {
      color: #fff;
    }
    .pay-error-code {
      white-space: pre-wrap;
      color: light-grey;
    }
    .price {
      color: ${props => props.theme.white};
      font-weight: 600;
      font-size: 30px;
    }
    .order-id {
      background: rgba(255, 255, 255, 0.1);
      display: inline-block;
      padding: 5px 10px;
      border-radius: 3px;
    }
    .order-date {
      font-size: 14px;
      padding: 0px;
      text-transform: uppercase;
      display: block;
      border-radius: 3px;
    }
    .ui.label {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
`
const OrderDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 20px;
  .customer-details,
  .item-details {
    background: rgba(230, 230, 230, 0.9);
    position: relative;
    top: -70px;
    border-radius: 3px;
    padding: 20px;
    color: rgba(81, 81, 96, 0.6);
    margin: 0 20px;
    box-shadow: rgba(50, 50, 93, 0.07) 0px 5px 15px 0px;
    h3 {
      margin: 2px 0 20px 0 !important;
      font-size: 23px;
    }
    h4 {
      font-weight: 600;
      border-bottom: 1px solid #cdcdce;
    }
    p {
      margin: 5px 0;
    }
  }
  .item {
    .description {
      background: rgba(100, 100, 100, 0.1);
      padding: 3px 5px;
      font-size: 12px;
      border-radius: 3px;
      margin: 5px 0;
      display: inline-block !important;
    }
  }
  .customer-details {
    h4 {
      padding-top: 20px;
    }
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    .customer-details {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      h3 {
        grid-column: 1 / span 2;
        grid-row: 1;
      }
      h4 {
        padding: 0;
      }
      div {
        grid-row: 2;
      }
    }
  }
  @media (max-width: 600px) {
    .customer-details {
      grid-template-columns: 1fr;
      div,
      h3 {
        grid-column: auto;
        grid-row: auto;
      }
    }
  }
`
const OrderItemRow = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr 130px;
  border-bottom: 1px solid #cdcdce;
  &:last-child {
    border-bottom: none;
    padding-bottom: 5px;
  }
  padding: 20px 0;
  grid-gap: 10px;
  .num {
    background: ${props => props.theme.grey2};
    color: ${props => props.theme.white};
    width: 25px;
    height: 25px;
    font-size: 12px;
    padding: 3px 3px;
    border-radius: 20px;
    text-align: center;
  }
  .name {
    color: ${props => props.theme.grey2};
    p {
      margin: 0;
    }
    .sku {
      background: rgba(0, 0, 0, 0.1);
      display: inline-block;
      padding: 2px 8px;
      font-size: 12px;
    }
  }
  .price {
    color: ${props => props.theme.grey2};
    align-self: center;
    justify-self: flex-end;
    font-weight: 600;
    span {
      opacity: 0.4;
      padding: 5px;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    .price {
      justify-self: flex-start;
    }
  }
`

// This component can either be provided an order-id or an order which is already fetched
const OrderItem = ({id, givenOrder, flush}) => {
  const [order, setOrder] = useState(givenOrder)
  const loggedIn = isLoggedIn()

  useEffect(() => {
    setOrder(givenOrder)
  }, [givenOrder])

  // Don't have order but have orderID, fetch order while loading
  if (!order && id && loggedIn) {
    // Need to fetch. Show loading
    return <EllipsisLoader height="450px" />
  }

  if (!loggedIn) {
    return (
      <Message
        error
        content="You have been logged out, please log in to view your order under My Account"
      />
    )
  }

  // Have order, so display order
  // console.log('order: ', order)
  if (order) {
    const {
      id,
      date_created,
      total,
      status,
      line_items,
      billing,
      shipping,
      payment_method,
      payment_method_account_details,
      payment_method_description,
      payment_method_instructions,
      payment_method_title,
      date_paid,
      shipping_total,
    } = order
    // Build order date and time information
    // console.log('current order: ', order)
    const orderDate = new Date(date_created.date)
    const orderDateString = `${
      mapMonths[orderDate.getMonth()]
    } ${orderDate.getDate()}, ${orderDate.getFullYear()}`
    // const paymentDate = new Date(date_paid.date)
    // const paymentDateString = `${
    //   mapMonths[paymentDate.getMonth()]
    // } ${paymentDate.getDate()}, ${paymentDate.getFullYear()}`
    const paymentError =
      order.order_notes.length > 0
        ? order.order_notes[0].comment_content
        : 'Your payment attempt seems to have failed'

    return (
      <OrderItemWrapper flush={flush}>
        <div className="order-details">
          <h3>Order Information</h3>
          <p className="order-id">Order #{id}</p>
          <p className="order-date">{`${orderDateString} - ${formatTime(
            orderDate,
          )}`}</p>
          <p className="price">{formatMoney(total)}</p>
          <h3>
            Shipping Cost:{' '}
            {shipping_total === '0.00' ? 'FREE' : formatMoney(shipping_total)}
          </h3>
          <PayLabels order={order} />
          {/* <p className="order-date">
            Date paid: {`${paymentDateString} - ${formatTime(paymentDate)}`}
          </p> */}
          <p>
            Payment method selected: {payment_method_description} <br />
            {status === 'failed' && paymentError}
          </p>
          {payment_method_account_details && (
            <p>
              Account name: {payment_method_account_details[0].account_name}
              <br />
              Account number: {payment_method_account_details[0].account_number}
              <br />
              Bank Name: {payment_method_account_details[0].bank_name}
              <br />
              Branch Code: {payment_method_account_details[0].sort_code}
              <br />
              Reference: #{id}
            </p>
          )}
        </div>
        <OrderDetailsWrapper>
          <div className="item-details">
            <h3>Order Items</h3>
            {line_items &&
              line_items.map((item, i) => (
                <OrderItemRow key={item.id}>
                  <div className="num">{i + 1}</div>
                  <div className="name">
                    <p>{item.name}</p>
                    {/* <p className="sku">
                      <strong>SKU:</strong> {item.sku}
                    </p> */}
                  </div>
                  <div className="price">
                    {item.quantity}
                    <span>x</span>
                    {formatMoney(item.total)}
                  </div>
                </OrderItemRow>
              ))}
          </div>
          <div className="customer-details">
            <h3>Customer Information</h3>
            <div>
              <h4>Billing address:</h4>
              <p>{billing.first_name + ' ' + billing.last_name}</p>
              <p>{billing.address_1}</p>
              <p>{billing.address_2}</p>
              <p>{billing.city}</p>
              <p>{billing.postcode}</p>
            </div>
            <div>
              <h4>Shipping address:</h4>
              <p>{shipping.first_name + ' ' + shipping.last_name}</p>
              <p>{shipping.address_1}</p>
              <p>{shipping.address_2}</p>
              <p>{shipping.city}</p>
              <p>{shipping.postcode}</p>
            </div>
          </div>
        </OrderDetailsWrapper>
      </OrderItemWrapper>
    )
  }
  return null
}

export default OrderItem
