/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import React, {useContext, useEffect, useState} from 'react'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'

import styled from 'styled-components'
import EllipsisLoader from '../EllipsisLoader'
import CartContext from '../Context/CartContext'
import AuthContext from '../Context/AuthContext'
import {getUser, isLoggedIn} from '../../services/auth'
import {analyticsUpdateAccount} from '../../services/GoogleAnalytics'
import {pixelUpdateAccount} from '../../services/FacebookPixel'
import {isOnMobile} from '../../utils'
import {getCustomer, updateCustomer} from '../../utils/WordPress'

const EmailStyled = styled.p`
  text-align: center;
  font-weight: 600;
  color: ${props => props.theme.grey2};
  font-size: 20px;
  padding: ${props => (props.isMyAccount ? '0px' : '20px 0px 0px')};
`

const FormStyled = styled.div`
  .save-buttons-row {
    padding: 20px 20px 20px;
    background: ${props => props.theme.grey5};
    margin: 0 -20px;
    text-align: center;
    border-radius: 0 0 0.2857rem 0.2857rem;
  }
  .form-group-wrapper {
    padding: 0px 0 20px 0;
  }
  .ui.form .fields {
    margin: 0 0 20px 0;
  }
  .ui.fluid.labeled {
    input {
      border-radius: 0 3px 3px 0;
    }
  }
  .ui.horizontal.label {
    min-width: 160px;
    text-transform: uppercase;
    padding: 10px;
    margin: -10px 20px -10px -10px;
  }
  .ui.label {
    min-width: 160px;
    text-transform: uppercase;
    display: grid;
    align-items: center;
    margin: 0;
    border-radius: 3px 0 0 3px;
  }
  .ui.input.error {
    input {
      border-color: #d4909e;
      border-bottom-right-radius: 0;
    }
    .label {
      background-color: #d4909e;
      border-bottom-left-radius: 0;
    }
  }
  .ui.form .error.message {
    display: block !important;
  }
`

const AddressInfo = ({continueCallback, CartSummaryFooter}) => {
  const {address, setAddress} = useContext(CartContext)
  const {performLogout} = useContext(AuthContext)
  const [unsavedAddress, setUnsavedAddress] = useState(address)
  const [addressUnchanged, setAddressUnchanged] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [loading, setLoading] = useState('true')
  const [errors, setErrors] = useState([])

  const {token, ...tokenPayload} = getUser()

  const fetchAddress = () => {
    if (!isLoggedIn()) return
    /* Request customer address */
    // get address from WC
    getCustomer(token, performLogout)
      .then(async resp => {
        const body = await resp
        console.log('customer info: ', body)
        setAddress(body.billing)
        setUnsavedAddress(body.billing)
        setLoading('false')
      })
      .catch(error => {
        setLoading('failed')
        console.log(error)
      })
  }

  useEffect(() => {
    fetchAddress()
  }, [])

  useEffect(() => {
    if (JSON.stringify(unsavedAddress) === JSON.stringify(address)) {
      setAddressUnchanged(true)
    } else {
      setAddressUnchanged(false)
    }
  }, [unsavedAddress])

  const handleChange = event => {
    event.persist()
    // Change background to indicate field is changed
    if (address[event.target.name] !== event.target.value) {
      event.target.style.backgroundColor = 'azure' // so pretty
    } else {
      event.target.style.backgroundColor = ''
    }
    // Update input fields
    setUnsavedAddress(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSubmit = event => {
    if (event) event.preventDefault()
    const validationState = validate(unsavedAddress)
    setErrors(validationState)
    if (Object.keys(validationState).length === 0) {
      submitAddress()
      if (!addressUnchanged) {
        analyticsUpdateAccount()
        pixelUpdateAccount()
      }
    }
  }

  const submitAddress = () => {
    setLoading('true')

    const tmpAddress = {
      billing: {
        ...unsavedAddress,
        state: '-',
        country: 'New Zealand',
      },
    }
    if (tmpAddress.billing.address_2 === '') {
      tmpAddress.billing.address_2 = '-'
    }
    /* Update customer address */
    updateCustomer(tmpAddress, token, performLogout)
      .then(res => {
        console.log('customer info', res)
        if (res.billing) {
          setAddress(res.billing)
          setUnsavedAddress(res.billing)
        } else {
          // eslint-disable-next-line no-throw-literal
          throw 'Bad response'
        }
        setSubmitError(false)
        setLoading('false')
        if (continueCallback) continueCallback()
      })
      .catch(error => {
        console.log(error)
        setSubmitError(true)
        fetchAddress()
      })
  }

  if (!isLoggedIn()) {
    return <></>
  }
  if (loading === 'true') {
    return <EllipsisLoader height="450px" />
  }
  if (loading === 'failed') {
    return <Label color="red">Request failed</Label>
  }
  return (
    <>
      <EmailStyled isMyAccount={continueCallback == null}>
        {tokenPayload.user_email}
      </EmailStyled>
      {continueCallback == null ? <h2 className="red">My Address</h2> : null}
      {submitError && (
        <Message
          error
          content="Failed to submit address. Please try again or contact us for support"
        />
      )}
      <FormStyled>
        <Form onSubmit={handleSubmit}>
          <div className="form-group-wrapper">
            <Form.Group widths="equal">
              <Form.Field>
                <Input
                  label="First name"
                  name="first_name"
                  autoComplete="fname"
                  placeholder="Name"
                  autoFocus={!isOnMobile()}
                  fluid
                  value={unsavedAddress.first_name || ''}
                  onChange={handleChange}
                  className={errors.first_name && 'error'}
                />
                {errors.first_name && (
                  <div className="message error attached">
                    {errors.first_name}
                  </div>
                )}
              </Form.Field>
              <Form.Field>
                <Input
                  label="Last name"
                  name="last_name"
                  autoComplete="lname"
                  placeholder="Surname"
                  fluid
                  value={unsavedAddress.last_name || ''}
                  onChange={handleChange}
                  className={errors.last_name && 'error'}
                />
                {errors.last_name && (
                  <div className="message error attached">
                    {errors.last_name}
                  </div>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Input
                  label="Phone Number"
                  name="phone"
                  type="tel"
                  autoComplete="phone"
                  placeholder="000 000 0000"
                  fluid
                  value={unsavedAddress.phone || ''}
                  onChange={handleChange}
                  className={errors.phone && 'error'}
                />
                {errors.phone && (
                  <div className="message error attached">{errors.phone}</div>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Input
                  label="Address Line 1"
                  name="address_1"
                  autoComplete="shipping address-line1"
                  placeholder="123 Street"
                  fluid
                  value={unsavedAddress.address_1 || ''}
                  onChange={handleChange}
                  className={errors.address_1 && 'error'}
                />
                {errors.address_1 && (
                  <div className="message error attached">
                    {errors.address_1}
                  </div>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Input
                  label="Address Line 2"
                  name="address_2"
                  autoComplete="shipping address-line2"
                  placeholder="Suburb"
                  fluid
                  value={unsavedAddress.address_2 || ''}
                  onChange={handleChange}
                  className={errors.address_2 && 'error'}
                />
                {errors.address_2 && (
                  <div className="message error attached">
                    {errors.address_2}
                  </div>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Input
                  label="City"
                  name="city"
                  autoComplete="shipping address-level2"
                  placeholder="City"
                  fluid
                  value={unsavedAddress.city || ''}
                  onChange={handleChange}
                  className={errors.address_city && 'error'}
                />
                {errors.address_city && (
                  <div className="message error attached">
                    {errors.address_city}
                  </div>
                )}
              </Form.Field>
              <Form.Field>
                <Input
                  label="Postal Code"
                  name="postcode"
                  autoComplete="shipping postal-code"
                  placeholder="0000"
                  fluid
                  value={unsavedAddress.postcode || ''}
                  onChange={handleChange}
                  className={errors.postcode && 'error'}
                />
                {errors.postcode && (
                  <div className="message error attached">
                    {errors.postcode}
                  </div>
                )}
              </Form.Field>
            </Form.Group>
          </div>
          {continueCallback == null ? (
            <div className="save-buttons-row">
              <Button
                content="Save Address"
                disabled={addressUnchanged}
                type="submit"
                className="button-secondary"
                icon="edit"
              />
            </div>
          ) : null}
        </Form>
      </FormStyled>
      {continueCallback != null ? (
        <CartSummaryFooter className={'checkoutCartSummaryFooter'}>
          <div className="text">
            Click continue once your address is verified
          </div>
          <div className="action">
            <Button color="blue" floated="right" onClick={handleSubmit}>
              Continue
            </Button>
          </div>
        </CartSummaryFooter>
      ) : null}
    </>
  )
}
export default AddressInfo

/* Form validation with error messages */
const validate = address => {
  const errors = []
  if (!address.first_name) {
    errors.first_name = 'First name is required'
  }
  if (!address.last_name) {
    errors.last_name = 'Last name is required'
  }
  if (!address.address_1) {
    errors.address_1 = 'Address Line 1 is required'
  }
  if (!address.city) {
    errors.address_city = 'City is required'
  }
  if (!address.phone) {
    errors.phone = 'Phone number is required'
  } else if (!/^^\d\d\d\s*\d\d\d\s*\d\s*\d\d\d$$/.test(address.phone)) {
    errors.phone = 'Please use a 10 digit phone number'
  }
  if (!address.postcode) {
    errors.postcode = 'Postal code is required'
  } else if (!/^[0-9]{4}$/.test(address.postcode)) {
    errors.postcode = 'Please use a 4 digit postal code'
  }
  return errors
}
